<template>
  <div class="main-container">
      
      <img class="basic_img" src="@/assets/func_backimage.jpg">

      <img class="title_label" src="@/assets/page_title_img.png">

      <img class="big_image" src="@/assets/func_main_image.jpg">



      <button class="jump_bt_one" @click="jumpToAppDownload">下载悦居生活APP</button>
      <button class="jump_bt_two" @click="jumpToWxProgram">点击前往访客小程序</button>

     



  </div>

</template>

<script>
import { Toast,Dialog} from 'vant'
  export default {
      name:"FuncJumpView",
      components:{},
      data(){
          return{
              navConfig:{
                  ifback:true,
                  titleStr:"多功能跳转网页",
                  showLine:false,
                  bgc:'#fff',
                  rightIcons:[],
              },
              eqType:'',
              currentAuid:'',
              type:'',
              isWeChat:false

          }
      },
      methods:{
          jumpToWxProgram(){
            let queryEc = ''
            if(this.type === 'authApply'){
              const wxQuery = 'auId=' + this.currentAuid
              queryEc = '&query=' + encodeURIComponent(wxQuery)

            }
            
            //  Dialog.alert({
            //           title: '查询成功',
            //           message:queryEc,
                      
            //           closeOnPopstate: false,
            //           zIndex: 9999999
            //           }).then(() => {
            //           // on close
            //           });
            //           console.log(queryEc)


              window.location.href = 'weixin://dl/business/?appid=wx9db26d5c90174230&path=pages/index/index' + queryEc;

          },

          jumpToAppDownload(){
            if(this.eqType === 'iOS'){
              if(this.isWeChat){
                Dialog.alert({
                      title: '请用浏览器打开',
                      message:'请点击右上角【...】，选择【在默认浏览器中打开】',
                      closeOnPopstate: false,
                      zIndex: 9999999
                      }).then(() => {
                      // on close
                      });

              }else{
                window.location.href = 'https://itunes.apple.com/cn/app/ai-yu/id1352856463?mt=8';
              }
            }else if(this.eqType === 'Android'){
              window.location.href = 'https://sj.qq.com/appdetail/com.cce.yunnanuc';
            }else{
              Toast({
                  message:'请用手机微信或浏览器打开此网页下载app',
                  zIndex:99999999
              })
            }
            

          }

      },
      mounted(){

          const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.get('parameter')){
    const param = urlParams.get('parameter')
    const dic = JSON.parse(param)
    if(dic.type){
      const type = dic.type
      this.type = type
      if(type === 'authApply'){
        //访客授权短信链接
        this.currentAuid = dic.auId
        

        // Dialog.alert({
        //               title: '查询成功',
        //               message:dic.type + '///' + dic.auId,
                      
        //               closeOnPopstate: false,
        //               zIndex: 9999999
        //               }).then(() => {
        //               // on close
        //               });

      }else{
        
      }

    }else{
      //格式不对，当作无参数网页处理
    }

    

  }else if(urlParams.get('GTST')){
    //扫描了门禁码：意图：1、下载app 2、前往访客小程序

  }else{
    //无参数网页

  }


  //判断打开此网页的设备
  let u = navigator.userAgent;
  if (/Android/i.test(u)) {
    // 安卓设备
    this.eqType = 'Android';
} else if (/iPhone|iPad|iPod/i.test(u)) {
    // iOS设备
    this.eqType = 'iOS';
} else if (/Win/i.test(u)) {
    // Windows设备
    this.eqType = 'Windows';
} else if (/Mac/i.test(u) && !/iPhone|iPad|iPod/i.test(u)) {
    // Mac设备，排除 iOS 设备
    this.eqType = 'Mac';
} else {
  this.eqType = 'Unknown';
}

let abc = '不是从微信打开的'
if (/MicroMessenger/i.test(u)) {
      this.isWeChat = true;
      abc = '是从微信打开的'
    }

                // Dialog.alert({
                //       title: JSON.stringify(this.isWeChat),
                //       message:abc,
                //       closeOnPopstate: false,
                //       zIndex: 9999999
                //       }).then(() => {
                //       // on close
                //       });



      

  
      },

      beforeDestroy(){
      }

  }
</script>

<style lang="less" scoped>
@baseSize: 4.05vw;
@basehhSizeh: 8.8vh;
.main-container{
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: #f6f6f6;
    z-index: 99999;
    
}

.jump_bt_one{
  position: relative;
  width: 86vw;
  margin-left: 7vw;
  margin-top: 72vh;
  height: 53px;
  border: none;
  border-radius:  25px;
  color: white;
  background-color: #927cf9;
  box-shadow: 0 0.5vw 3vw rgba(0 0 0 / 36%);
  font-size: 18px;
  font-weight: 600;

}


.jump_bt_two{
  position: relative;
  width: 86vw;
  margin-left: 7vw;
  margin-top: 20px;
  height: 53px;
  border: none;
  border-radius:  25px;
  color: white;
  background-color: #28bd70;
  box-shadow: 0 0.5vw 3vw rgba(0 0 0 / 36%);
  font-size: 18px;
  font-weight: 600;

}


.basic_img{
  position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: fill;
    filter: blur(20px);

}

.big_image{
  position: absolute;
  top:33vw;
  height: 45vh;
  left:50%;
  transform: translateX(-50%);
}


.title_label{
  position: absolute;
  top: 6vw;
  left: 10vw;
  width: 80vw;
  height: 23.86vw;

}


</style>