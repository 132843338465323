import Vue from 'vue'
import App from './App.vue'

import {Dialog,Toast} from 'vant';

Vue.config.productionTip = false

Vue.use(Toast);
Vue.use(Dialog);

new Vue({
  render: h => h(App),
}).$mount('#app')
